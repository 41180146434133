import React from 'react';

const RejectionDetailsModal = ({ rejectionReason, rejectionImages, closeModal }) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Reddetme Detayları</h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500"><strong>Reddetme Sebebi:</strong> {rejectionReason}</p>
            {rejectionImages?.length > 0 && (
              <div className="grid grid-cols-3 gap-2 mt-4">
                {rejectionImages.map((image, index) => (
                  <img key={index} src={image} alt={`rejection-${index}`} className="w-full h-auto rounded-md" />
                ))}
              </div>
            )}
          </div>
          <div className="mt-4 sm:mt-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={closeModal}
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectionDetailsModal;
