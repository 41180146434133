import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Swal from 'sweetalert2';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [role, setRole] = useState(null);  // Role state'i eklendi
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to check if the user has the right permissions
    const checkUserPermissions = async (user, navigate) => {
      try {
        console.log('Checking user permissions for UID:', user.uid);

        // Query Firestore to see if the user is an admin
        const docRef = doc(db, 'adminusers', user.uid);
        console.log('Fetching document from Firestore:', docRef.path);
        const docSnap = await getDoc(docRef);

        // If user is found in the adminusers collection, log and set the role
        if (docSnap.exists()) {
          const userData = docSnap.data();
          const userRole = userData.role;
          console.log('Admin user found:', userData);
          console.log('User role:', userRole); // Role değerini logla
          setRole(userRole);  // Role state'ine role bilgisini kaydet
          setCurrentUser({ ...user, role: userRole }); // Kullanıcıya role ekle
        } else {
          // If not found, sign out the user and redirect to login
          console.log('No admin user found for this UID:', user.uid);
          await auth.signOut();
          Swal.fire('Yetkisiz Giriş', 'Kullanıcı admin değil.', 'error');
          navigate('/login');
        }
      } catch (error) {
        // Handle errors and ensure the user is signed out and redirected
        console.error('Error checking user permissions:', error);
        await auth.signOut();
        setCurrentUser(null);
        setRole(null);  // Hata durumunda role sıfırlanır
        Swal.fire('Yetkisiz Giriş', 'Kullanıcı admin değil.', 'error');
        navigate('/login');
      }
    };

    // Listen for changes in the authentication state
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log('User authenticated, checking permissions...');
        // Call checkUserPermissions when user is authenticated
        await checkUserPermissions(user);
      } else {
        console.log('No user authenticated');
        setCurrentUser(null);
        setRole(null);  // Kullanıcı çıkış yapınca role sıfırlanır
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Provide the current user and its role
  const value = {
    currentUser,
    role,  // Role bilgisini context'e ekliyoruz
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
