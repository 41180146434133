import React, { useState, useEffect } from 'react';
import { collectionGroup, getDocs, query, orderBy, limit, startAfter, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import AdDetailModal from './AdDetailModal';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Ads = () => {
  const [ads, setAds] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [adsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('Hepsi');
  const [selectedAd, setSelectedAd] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    fetchSectors();
    fetchAds();
  }, [currentPage, searchTerm, selectedFilter]);

  const fetchSectors = async () => {
    try {
      const sectorsSnapshot = await getDocs(collection(db, 'sectors'));
      const sectorsList = sectorsSnapshot.docs.map((doc) => doc.data().name);
      setSectors(sectorsList);
    } catch (error) {
      console.error('Error fetching sectors:', error);
    }
  };

  const fetchAds = async () => {
    setLoading(true);
    try {
      let adsQuery = query(collectionGroup(db, 'ads'), orderBy('createdAt'), limit(adsPerPage));

      const adsSnapshot = await getDocs(adsQuery);
      let adsList = adsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return { id: doc.id, ...data };
      });

      if (searchTerm) {
        adsList = adsList.filter((ad) => ad.title.toLowerCase().includes(searchTerm.toLowerCase()));
      }

      if (selectedFilter !== 'Hepsi') {
        adsList = adsList.filter((ad) => ad.sectors?.includes(selectedFilter));
      }

      const lastVisibleDoc = adsSnapshot.docs[adsSnapshot.docs.length - 1];
      setLastVisible(lastVisibleDoc);
      setAds(adsList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ads:', error);
      setLoading(false);
    }
  };

  const handleNextPage = async () => {
    if (lastVisible) {
      setLoading(true);
      try {
        let adsQuery = query(
          collectionGroup(db, 'ads'),
          orderBy('createdAt'),
          startAfter(lastVisible),
          limit(adsPerPage)
        );

        const adsSnapshot = await getDocs(adsQuery);
        let adsList = adsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return { id: doc.id, ...data };
        });

        if (searchTerm) {
          adsList = adsList.filter((ad) => ad.title.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        if (selectedFilter !== 'Hepsi') {
          adsList = adsList.filter((ad) => ad.sectors?.includes(selectedFilter));
        }

        const lastVisibleDoc = adsSnapshot.docs[adsSnapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);
        setAds((prevAds) => [...prevAds, ...adsList]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching ads:', error);
        setLoading(false);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleModalOpen = (ad) => {
    setSelectedAd(ad);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedAd(null);
    setIsModalOpen(false);
  };

  const handleExport = async () => {
    setExporting(true);
    setProgress(0);

    try {
      const adsSnapshot = await getDocs(collectionGroup(db, 'ads'));
      const adsList = adsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ID: doc.id,
          Title: data.title,
          AdType: data.adType,
          CompanyID: data.companyId,
          Content: data.content,
          CreatedAt: data.createdAt ? new Date(data.createdAt.seconds * 1000).toLocaleString() : '',
          DueDate: data.dueDate ? new Date(data.dueDate.seconds * 1000).toLocaleDateString() : '',
          Duration: data.duration,
          EndDate: data.endDate ? new Date(data.endDate.seconds * 1000).toLocaleString() : '',
          Images: data.images,
          MaxBid: data.maxBid,
          PaymentMethod: data.paymentMethod,
          Quantity: data.quantity,
          RequestSample: data.requestSample ? 'Yes' : 'No',
          Sectors: data.sectors?.join(', '),
          Unit: data.unit,
          UserID: data.userId,
        };
      });

      const chunkSize = 10;
      const totalChunks = Math.ceil(adsList.length / chunkSize);

      for (let i = 0; i < totalChunks; i++) {
        const chunk = adsList.slice(i * chunkSize, (i + 1) * chunkSize);
        const ws = XLSX.utils.json_to_sheet(chunk);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Ads');
        XLSX.writeFile(wb, `ads_data_part${i + 1}.xlsx`);

        setProgress(Math.round(((i + 1) / totalChunks) * 100));
      }

      toast.success('Data exported successfully!');
    } catch (error) {
      console.error('Error exporting data:', error);
      toast.error('Error exporting data.');
    }

    setExporting(false);
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4 space-y-2 md:space-y-0 md:space-x-4">
        <div className="flex flex-col md:flex-row gap-2">
          <select
            className="py-2 px-4 rounded-lg border border-gray-300"
            value={selectedFilter}
            onChange={handleFilterChange}
          >
            <option value="Hepsi">Hepsi</option>
            {sectors.map((sector) => (
              <option key={sector} value={sector}>
                {sector}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Search by Title"
            className="p-2 border border-gray-300 rounded-md w-full md:w-72"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <button
          onClick={handleExport}
          className="py-2 px-4 bg-blue-500 text-white rounded-lg"
        >
          Dışarı Çıkart
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                ID
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Title
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Ad Type
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Company ID
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Created At
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Details</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              <tr>
                <td colSpan="6" className="py-4 text-center text-sm font-medium text-gray-500">
                  Loading...
                </td>
              </tr>
            ) : (
              ads.map((ad) => (
                <tr key={ad.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {ad.id}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{ad.title}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{ad.adType}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{ad.companyId}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                    {new Date(ad.createdAt.seconds * 1000).toLocaleString()}
                  </td>
                  <td className="whitespace-nowrap py-4 text-right text-sm font-medium">
                    <button
                      onClick={() => handleModalOpen(ad)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center mt-4 gap-2">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Önceki
        </button>
        <span>
          {ads.length > 0 &&
            `${(currentPage - 1) * adsPerPage + 1} - ${Math.min(currentPage * adsPerPage, ads.length)} / ${
              ads.length
            }`}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage * adsPerPage >= ads.length}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Sonraki
        </button>
      </div>
      <ToastContainer />

      {selectedAd && (
        <AdDetailModal ad={selectedAd} isOpen={isModalOpen} onClose={handleModalClose} />
      )}

      {exporting && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-lg font-medium text-gray-900">Exporting Data</h2>
            <p className="text-gray-700">Please do not close the screen.</p>
            <CircularProgressbar value={progress} text={`${progress}%`} className="mt-4" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Ads;
