import React, { useState, useEffect } from 'react';
import { collection, getDocs, collectionGroup } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [totalAds, setTotalAds] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);

  useEffect(() => {
    fetchTotalCompanies();
    fetchTotalAds();
    fetchTotalOrders();
  }, []);

  const fetchTotalCompanies = async () => {
    try {
      const q = collection(db, 'companies');
      const snapshot = await getDocs(q);
      setTotalCompanies(snapshot.size);
    } catch (error) {
      console.error('Error fetching total companies:', error);
    }
  };

  const fetchTotalAds = async () => {
    let totalAdsCount = 0;

    try {
      const allAdsQuery = collectionGroup(db, 'ads');
      const allAdsSnapshot = await getDocs(allAdsQuery);

      allAdsSnapshot.forEach((doc) => {
        totalAdsCount++;
      });

      setTotalAds(totalAdsCount);
    } catch (error) {
      console.error('Error fetching total ads:', error);
    }
  };

  const fetchTotalOrders = async () => {
    try {
      const ordersQuery = collection(db, 'orders');
      const ordersSnapshot = await getDocs(ordersQuery);

      setTotalOrders(ordersSnapshot.size);
    } catch (error) {
      console.error('Error fetching total orders:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-xl text-gray-500">Loading...</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-2">Toplam Şirket Sayısı</h2>
          <p className="text-3xl">{totalCompanies}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-2">Toplam İlan Sayısı</h2>
          <p className="text-3xl">{totalAds}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-2">Toplam Sipariş Sayısı</h2>
          <p className="text-3xl">{totalOrders}</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
