import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { useAuth } from '../context/AuthContext';  // AuthProvider'dan role bilgisini almak için


const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { role } = useAuth();  // AuthProvider'dan role bilgisini alıyoruz


  return (
    <div className="flex h-screen">
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} role={role} /> 
      <div className={`flex-1 flex flex-col overflow-hidden xl:pl-64 ${sidebarOpen ? 'bg-black/40' : ''}`}>
        <Header setSidebarOpen={setSidebarOpen} />
        <main className="flex-1 overflow-y-auto p-4 bg-gray-100">
          {children}
        </main>
        {/* Background overlay when the sidebar is open on mobile */}
        {sidebarOpen && (
          <div
            className="fixed inset-0 bg-black opacity-40 z-30 md:hidden"
            onClick={() => setSidebarOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Layout;
