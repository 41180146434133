import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import Swal from 'sweetalert2';

const ContactInfos = () => {
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [warehouseAddress, setWarehouseAddress] = useState('');
  const [socials, setSocials] = useState([
    { name: 'Facebook', href: '', icon: 'FacebookIcon' },
    { name: 'Instagram', href: '', icon: 'InstagramIcon' },
    { name: 'X', href: '', icon: 'XIcon' },
    { name: 'GitHub', href: '', icon: 'GitHubIcon' },
    { name: 'YouTube', href: '', icon: 'YouTubeIcon' },
  ]);

  useEffect(() => {
    fetchContactInfo();
  }, []);

  const fetchContactInfo = async () => {
    try {
      const querySnapshot = await getDoc(doc(db, 'contact', 'contactInfo'));
      if (querySnapshot.exists()) {
        const data = querySnapshot.data();
        setPhone(data.phone || '');
        setAddress(data.address || '');
        setWarehouseAddress(data.warehouseAddress || '');
        setSocials(data.socials || socials);
      }
    } catch (error) {
      console.error('Error fetching contact info:', error);
    }
  };

  const handleSocialChange = (index, value) => {
    const newSocials = [...socials];
    newSocials[index].href = value;
    setSocials(newSocials);
  };

  const handleSave = async () => {
    try {
      await setDoc(doc(db, 'contact', 'contactInfo'), {
        phone,
        address,
        warehouseAddress,
        socials,
      });
      Swal.fire('Success', 'Contact information saved successfully.', 'success');
    } catch (error) {
      console.error('Error saving contact info:', error);
      Swal.fire('Error', 'Error saving contact info.', 'error');
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">İletişim Bilgileri Yönetim Paneli</h1>
      
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-semibold mb-4">İletişim Bilgileri</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Telefon</label>
          <input
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Adres</label>
          <input
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Mal Adresi</label>
          <input
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={warehouseAddress}
            onChange={(e) => setWarehouseAddress(e.target.value)}
          />
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Sosyal Hesaplar</h2>
        {socials.map((social, index) => (
          <div key={index} className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{social.name}</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={social.href}
              onChange={(e) => handleSocialChange(index, e.target.value)}
            />
          </div>
        ))}
        <button
          onClick={handleSave}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Kaydet
        </button>
      </div>
    </div>
  );
};

export default ContactInfos;
