import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import OrderDetailsModal from './OrderDetailsModal';
import DeliveryDetailsModal from './DeliveryDetailsModal';
import RejectionDetailsModal from './RejectionDetailsModal';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [isDeliveryDetailsModalOpen, setIsDeliveryDetailsModalOpen] = useState(false);
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('Hepsi');
  const [sectors, setSectors] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [totalOrders, setTotalOrders] = useState(0);

  useEffect(() => {
    fetchSectors();
    fetchOrders();
  }, [searchTerm, selectedFilter, currentPage]);

  const fetchSectors = async () => {
    try {
      const sectorsSnapshot = await getDocs(collection(db, 'sectors'));
      const sectorsList = sectorsSnapshot.docs.map((doc) => doc.data().name);
      setSectors(sectorsList);
    } catch (error) {
      console.error('Sektörleri alırken hata:', error);
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const ordersCollection = collection(db, 'orders');
      const ordersQuery = query(ordersCollection, orderBy('orderDate', 'desc')); // Tarihe göre sıralama (yeniden eskiye)
      const ordersSnapshot = await getDocs(ordersQuery);
      let ordersList = ordersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      if (searchTerm) {
        ordersList = ordersList.filter((order) =>
          order.ad?.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (selectedFilter !== 'Hepsi') {
        ordersList = ordersList.filter((order) => order.ad?.sectors?.includes(selectedFilter));
      }

      setTotalOrders(ordersList.length);

      const offset = (currentPage - 1) * ordersPerPage;
      const paginatedOrders = ordersList.slice(offset, offset + ordersPerPage);

      const fetchCompanyData = async (companyId) => {
        const docRef = doc(db, 'companies', companyId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data().companyName : 'Firma Adı Yok';
      };

      const fetchLogisticsData = async (orderId) => {
        const logisticsRef = doc(db, 'orders', orderId, 'logistics', orderId);
        const logisticsSnap = await getDoc(logisticsRef);
        if (logisticsSnap.exists()) {
          return logisticsSnap.data();
        } else {
          return { status: 'pending', description: 'Kargo / Lojistik bilgileri bekleniyor' };
        }
      };

      const ordersWithCompanyNamesAndStatus = await Promise.all(
        paginatedOrders.map(async (order) => {
          const buyerName = await fetchCompanyData(order.companyId);
          const sellerName = order.bid?.bidderCompanyId
            ? await fetchCompanyData(order.bid.bidderCompanyId)
            : 'Mevcut Değil';

          const logisticsData = await fetchLogisticsData(order.id);

          let deliveryStatus;
          if (logisticsData.status === 'accepted') {
            deliveryStatus = 'Teslimat Tamamlandı';
          } else if (logisticsData.status === 'rejected') {
            deliveryStatus = 'Reddedildi';
          } else {
            deliveryStatus = logisticsData.description || 'Teslimat Bekleniyor';
          }

          return { ...order, buyerName, sellerName, deliveryStatus, logisticsData };
        })
      );

      setOrders(ordersWithCompanyNamesAndStatus);
      setLoading(false);
    } catch (error) {
      console.error('Siparişleri alırken hata:', error);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleExport = async () => {
    setExporting(true);

    try {
      const ordersSnapshot = await getDocs(collection(db, 'orders'));
      const ordersList = await Promise.all(
        ordersSnapshot.docs.map(async (orderDoc) => {
          const data = orderDoc.data();

          const buyerCompanyRef = doc(db, 'companies', data.companyId);
          const buyerCompanySnap = await getDoc(buyerCompanyRef);
          const buyerCompanyName = buyerCompanySnap.exists()
            ? buyerCompanySnap.data().companyName
            : 'Firma Adı Yok';

          let sellerCompanyName = 'Mevcut Değil';
          if (data.bid?.bidderCompanyId) {
            const sellerCompanyRef = doc(db, 'companies', data.bid.bidderCompanyId);
            const sellerCompanySnap = await getDoc(sellerCompanyRef);
            sellerCompanyName = sellerCompanySnap.exists()
              ? sellerCompanySnap.data().companyName
              : 'Mevcut Değil';
          }

          return {
            ID: orderDoc.id,
            AdTitle: data.ad?.title,
            AdDescription: data.ad?.description,
            Sectors: data.ad?.sectors?.join(', '),
            BuyerCompanyName: buyerCompanyName,
            BuyerCompanyID: data.companyId,
            SellerCompanyName: sellerCompanyName,
            SellerCompanyID: data.bid?.bidderCompanyId || 'Mevcut Değil',
            BidAmount: data.bid?.bidAmount || 'Mevcut Değil',
            AdType: data.ad?.adType,
            CreatedAt: data.ad?.createdAt
              ? new Date(data.ad.createdAt.seconds * 1000).toLocaleDateString()
              : '',
            DueDate: data.ad?.dueDate
              ? new Date(data.ad.dueDate.seconds * 1000).toLocaleDateString()
              : '',
            Duration: data.ad?.duration,
            Content: data.ad?.content,
            RequestSample: data.ad?.requestSample ? 'Evet' : 'Hayır',
            Images: Array.isArray(data.ad?.images) ? data.ad.images.join(', ') : 'Resim Yok',
            DeliveryStatus: data.deliveryConfirmed === true ? 'Teslimat Tamamlandı' : 'Teslimat Bekleniyor',
          };
        })
      );

      const ws = XLSX.utils.json_to_sheet(ordersList);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Orders');
      XLSX.writeFile(wb, 'orders_data.xlsx');

      toast.success('Veriler başarıyla dışa aktarıldı!');
    } catch (error) {
      console.error('Verileri dışa aktarırken hata:', error);
      toast.error('Verileri dışa aktarırken hata oluştu.');
    }

    setExporting(false);
  };

  const handleOpenOrderDetailsModal = (order) => {
    setSelectedOrder(order);
    setIsOrderDetailsModalOpen(true);
  };

  const handleCloseOrderDetailsModal = () => {
    setSelectedOrder(null);
    setIsOrderDetailsModalOpen(false);
  };

  const handleOpenDeliveryDetailsModal = (order) => {
    setSelectedOrder(order);
    setIsDeliveryDetailsModalOpen(true);
  };

  const handleCloseDeliveryDetailsModal = () => {
    setSelectedOrder(null);
    setIsDeliveryDetailsModalOpen(false);
  };

  const handleOpenRejectionModal = (order) => {
    setSelectedOrder(order);
    setIsRejectionModalOpen(true);
  };

  const handleCloseRejectionModal = () => {
    setSelectedOrder(null);
    setIsRejectionModalOpen(false);
  };

  const handleNextPage = () => {
    if (currentPage * ordersPerPage < totalOrders) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4 space-y-2 md:space-y-0 md:space-x-4">
        <div className="flex flex-col md:flex-row gap-2">
          <select
            className="py-2 px-4 rounded-lg border border-gray-300"
            value={selectedFilter}
            onChange={handleFilterChange}
          >
            <option value="Hepsi">Hepsi</option>
            {sectors.map((sector) => (
              <option key={sector} value={sector}>
                {sector}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="İlan Adı ile Ara"
            className="p-2 border border-gray-300 rounded-md w-full md:w-72"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <button onClick={handleExport} className="py-2 px-4 bg-blue-500 text-white rounded-lg">
          Dışa Aktar
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Sipariş Tarihi</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">İlan Adı</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Satıcı Firma Adı</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Alıcı Firma Adı</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Sipariş Durumu</th>
              <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Detaylar</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              <tr>
                <td colSpan="6" className="py-4 text-center text-sm font-medium text-gray-500">
                  Yükleniyor...
                </td>
              </tr>
            ) : (
              orders.map((order) => (
                <tr key={order.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {order.orderDate
                      ? new Date(order.orderDate.seconds * 1000).toLocaleDateString() // Tarihi okunabilir formata çevirme
                      : 'Tarih Bilgisi Yok'}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{order.ad?.title}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{order.sellerName}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{order.buyerName}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                    {order.deliveryStatus}
                    {order.deliveryStatus === 'Reddedildi' && (
                      <button
                        onClick={() => handleOpenRejectionModal(order)}
                        className="ml-2 text-red-600 hover:text-red-900"
                      >
                        Detaylar
                      </button>
                    )}
                  </td>
                  <td className="whitespace-nowrap py-4 text-right text-sm font-medium">
                    <button
                      onClick={() => handleOpenOrderDetailsModal(order)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Sipariş Detayları
                    </button>
                    <button
                      onClick={() => handleOpenDeliveryDetailsModal(order)}
                      className="text-indigo-600 hover:text-indigo-900 ml-4"
                    >
                      Teslimat Detayları
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center mt-4 gap-2">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Önceki
        </button>
        <span>
          {totalOrders > 0 &&
            `${(currentPage - 1) * ordersPerPage + 1} - ${Math.min(currentPage * ordersPerPage, totalOrders)} / ${
              totalOrders
            }`}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage * ordersPerPage >= totalOrders}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Sonraki
        </button>
      </div>

      <ToastContainer />

      {exporting && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-lg font-medium text-gray-900">Veriler Dışa Aktarılıyor</h2>
            <p className="text-gray-700">Lütfen ekranı kapatmayın.</p>
          </div>
        </div>
      )}

      {isOrderDetailsModalOpen && (
        <OrderDetailsModal order={selectedOrder} closeModal={handleCloseOrderDetailsModal} />
      )}

      {isDeliveryDetailsModalOpen && selectedOrder && (
        <DeliveryDetailsModal
          orderId={selectedOrder.id}
          logisticsData={selectedOrder.logisticsData}
          closeModal={handleCloseDeliveryDetailsModal}
        />
      )}

      {isRejectionModalOpen && selectedOrder && (
        <RejectionDetailsModal
          rejectionReason={selectedOrder.logisticsData?.rejectionReason}
          rejectionImages={selectedOrder.logisticsData?.rejectionImages}
          closeModal={handleCloseRejectionModal}
        />
      )}
    </div>
  );
};

export default Orders;
