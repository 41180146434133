import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db, storage } from '../../firebaseConfig';
import Swal from 'sweetalert2';

const Seo = () => {
  const [logo, setLogo] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedLogoUrl, setUploadedLogoUrl] = useState('');
  const [siteTitle, setSiteTitle] = useState('');
  const [siteDescription, setSiteDescription] = useState('');
  const [favicon, setFavicon] = useState(null);
  const [uploadedFaviconUrl, setUploadedFaviconUrl] = useState('');

  useEffect(() => {
    fetchSeoData();
  }, []);

  const fetchSeoData = async () => {
    try {
      console.log("Fetching SEO data...");
      const docRef = doc(db, 'cms', 'seo');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("SEO data:", data);
        setUploadedLogoUrl(data.logoUrl || '');
        setSiteTitle(data.siteTitle || '');
        setSiteDescription(data.siteDescription || '');
        setUploadedFaviconUrl(data.faviconUrl || '');
      } else {
        console.log("No SEO data found.");
      }
    } catch (error) {
      console.error('Error fetching SEO data:', error);
    }
  };

  const handleLogoChange = (e) => {
    if (e.target.files[0]) {
      setLogo(e.target.files[0]);
    }
  };

  const handleFaviconChange = (e) => {
    if (e.target.files[0]) {
      setFavicon(e.target.files[0]);
    }
  };

  const handleUpload = async (file, type) => {
    if (!file) {
      Swal.fire('Error', `Please select a ${type} to upload.`, 'error');
      return;
    }

    const storageRef = ref(storage, `cms/${type}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Handle progress, if needed
      },
      (error) => {
        console.error(`Upload error for ${type}:`, error);
        Swal.fire('Error', `Error uploading the ${type}.`, 'error');
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        if (type === 'logo') {
          await saveSeoData({ logoUrl: downloadURL });
          setUploadedLogoUrl(downloadURL);
        } else if (type === 'favicon') {
          await saveSeoData({ faviconUrl: downloadURL });
          setUploadedFaviconUrl(downloadURL);
        }
        setUploading(false);
        Swal.fire('Success', `${type} uploaded successfully.`, 'success');
      }
    );
  };

  const saveSeoData = async (data) => {
    try {
      const docRef = doc(db, 'cms', 'seo');
      await setDoc(docRef, { ...data, siteTitle, siteDescription }, { merge: true });
    } catch (error) {
      console.error('Error saving SEO data:', error);
      Swal.fire('Error', 'Error saving the SEO data.', 'error');
    }
  };

  const handleSave = async () => {
    try {
      await saveSeoData({});
      Swal.fire('Success', 'SEO data saved successfully.', 'success');
    } catch (error) {
      console.error('Error saving SEO data:', error);
      Swal.fire('Error', 'Error saving SEO data.', 'error');
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">SEO Yönetim Paneli</h1>
      
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-semibold mb-4">Logo Yükleme</h2>
        <div className="flex items-center space-x-4">
          <input type="file" onChange={handleLogoChange} className="border p-2 rounded-md" />
          <button
            onClick={() => handleUpload(logo, 'logo')}
            disabled={uploading}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            {uploading ? 'Yükleniyor...' : 'Logo Yükle'}
          </button>
        </div>
        {uploadedLogoUrl && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Yüklenen Logo</h3>
            <img src={uploadedLogoUrl} alt="Logo" className="w-32 h-32 object-contain" />
          </div>
        )}
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-semibold mb-4">Favicon Yükleme</h2>
        <div className="flex items-center space-x-4">
          <input type="file" onChange={handleFaviconChange} className="border p-2 rounded-md" />
          <button
            onClick={() => handleUpload(favicon, 'favicon')}
            disabled={uploading}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            {uploading ? 'Yükleniyor...' : 'Favicon Yükle'}
          </button>
        </div>
        {uploadedFaviconUrl && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Yüklenen Favicon</h3>
            <img src={uploadedFaviconUrl} alt="Favicon" className="w-16 h-16 object-contain" />
          </div>
        )}
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-semibold mb-4">Site Bilgileri</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Site Başlığı</label>
          <input
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={siteTitle}
            onChange={(e) => setSiteTitle(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Site Açıklaması</label>
          <textarea
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={siteDescription}
            onChange={(e) => setSiteDescription(e.target.value)}
          />
        </div>
        <button
          onClick={handleSave}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Kaydet
        </button>
      </div>
    </div>
  );
};

export default Seo;
