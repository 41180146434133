import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Swal from 'sweetalert2';
import CompanyDetailModal from './CompanyDetailModal';

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('Tümü');
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const companiesPerPage = 5;

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    filterCompanies();
  }, [searchTerm, companies, currentPage]);

  // Fetch companies from the database
  const fetchCompanies = async () => {
    setLoading(true);
    const q = query(collection(db, 'companies'), orderBy('companyName'));

    try {
      const snapshot = await getDocs(q);
      const companiesList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setCompanies(companiesList);
      setTotalCompanies(companiesList.length);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setLoading(false);
    }
  };

  // Filter companies based on search and pagination
  const filterCompanies = () => {
    let filtered = companies;

    if (searchTerm) {
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((company) =>
        company.companyName.toLowerCase().includes(lowercaseSearchTerm)
      );
    }

    const offset = (currentPage - 1) * companiesPerPage;
    const paginatedCompanies = filtered.slice(offset, offset + companiesPerPage);

    setFilteredCompanies(paginatedCompanies);
  };

  // Update company status
  const updateCompanyStatus = async (id, role, status) => {
    try {
      const companyRef = doc(db, 'companies', id);
      const updateData = role === 'Buyer' ? { isBuyerConfirmed: status } : { isSellerConfirmed: status };
      await updateDoc(companyRef, updateData);
      Swal.fire('Başarılı', 'Şirket durumu başarıyla güncellendi.', 'success');
      fetchCompanies();
    } catch (error) {
      Swal.fire('Hata!', 'Şirket durumunu güncellerken bir hata oluştu.', 'error');
    }
  };

  const handleApprove = (id, role) => {
    updateCompanyStatus(id, role, 'yes');
  };

  const handleReject = (id, role) => {
    updateCompanyStatus(id, role, 'no');
  };

  const handlePending = (id, role) => {
    updateCompanyStatus(id, role, 'pending');
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    setCurrentPage(1);
  };

  const openDetailModal = (companyId) => {
    setSelectedCompanyId(companyId);
    setIsDetailModalOpen(true);
  };

  const handleNextPage = () => {
    if (currentPage * companiesPerPage < totalCompanies) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4 space-y-2 md:space-y-0 md:space-x-4">
        <div className="flex flex-wrap gap-2">
          {['Tümü', 'Onaylı Alıcı', 'Onaylı Satıcı', 'Alıcı Onay Bekleyen', 'Satıcı Onay Bekleyen', 'Alıcı Red', 'Satıcı Red'].map((filter) => (
            <button
              key={filter}
              onClick={() => handleFilterClick(filter)}
              className={`py-2 px-4 rounded-lg ${
                selectedFilter === filter ? 'bg-gray-200 text-gray-800' : 'text-gray-500'
              }`}
            >
              {filter}
            </button>
          ))}
        </div>
        <div className="w-full md:w-auto">
          <input
            type="text"
            placeholder="Search"
            className="p-2 border border-gray-300 rounded-md w-full md:w-72"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div className="mb-4 text-sm text-gray-500">
        {totalCompanies > 0
          ? `${totalCompanies} şirketten ${(currentPage - 1) * companiesPerPage + 1} - ${
              currentPage * companiesPerPage > totalCompanies ? totalCompanies : currentPage * companiesPerPage
            } arası gösteriliyor.`
          : 'Hiç şirket bulunamadı.'}
      </div>

      <div className="overflow-x-auto w-full max-w mx-auto shadow-lg rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Şirket Adı
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Şirket Ünvanı
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Şehir
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                İlçe
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Posta Kodu
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Sektörler
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Alıcı Onayı
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Satıcı Onayı
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              <tr>
                <td colSpan="9" className="py-4 text-center text-sm font-medium text-gray-500">
                  Loading...
                </td>
              </tr>
            ) : (
              filteredCompanies.map((company) => (
                <tr key={company.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {company.companyName}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {company.companyTitle}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.city}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.district}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.postalCode}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {company.sectors.join(', ')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {company.isBuyerConfirmed === 'pending' ? (
                      <>
                        <button
                          onClick={() => handleApprove(company.id, 'Buyer')}
                          className="bg-blue-500 text-white text-xs px-2 py-1 rounded mr-2"
                        >
                          Onayla
                        </button>
                        <button
                          onClick={() => handleReject(company.id, 'Buyer')}
                          className="bg-red-500 text-white text-xs px-2 py-1 rounded"
                        >
                          Reddet
                        </button>
                      </>
                    ) : company.isBuyerConfirmed === 'yes' ? (
                      <button
                        onClick={() => handlePending(company.id, 'Buyer')}
                        className="bg-yellow-500 text-white text-xs px-2 py-1 rounded"
                      >
                        Onayı Kaldır
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => handlePending(company.id, 'Buyer')}
                          className="bg-yellow-500 text-white text-xs px-2 py-1 rounded"
                        >
                          Beklemede Yap
                        </button>
                        <button
                          onClick={() => handleApprove(company.id, 'Buyer')}
                          className="bg-blue-500 text-white text-xs px-2 py-1 rounded ml-2"
                        >
                          Kabul Et
                        </button>
                      </>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {company.isSellerConfirmed === 'pending' ? (
                      <>
                        <button
                          onClick={() => handleApprove(company.id, 'Seller')}
                          className="bg-blue-500 text-white text-xs px-2 py-1 rounded mr-2"
                        >
                          Onayla
                        </button>
                        <button
                          onClick={() => handleReject(company.id, 'Seller')}
                          className="bg-red-500 text-white text-xs px-2 py-1 rounded"
                        >
                          Reddet
                        </button>
                      </>
                    ) : company.isSellerConfirmed === 'yes' ? (
                      <button
                        onClick={() => handlePending(company.id, 'Seller')}
                        className="bg-yellow-500 text-white text-xs px-2 py-1 rounded"
                      >
                        Onayı Kaldır
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => handlePending(company.id, 'Seller')}
                          className="bg-yellow-500 text-white text-xs px-2 py-1 rounded"
                        >
                          Beklemede Yap
                        </button>
                        <button
                          onClick={() => handleApprove(company.id, 'Seller')}
                          className="bg-blue-500 text-white text-xs px-2 py-1 rounded ml-2"
                        >
                          Kabul Et
                        </button>
                      </>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <button
                      onClick={() => openDetailModal(company.id)}
                      className="bg-indigo-500 text-white text-xs px-2 py-1 rounded"
                    >
                      Detay
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-center md:justify-between">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Önceki
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage * companiesPerPage >= totalCompanies}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Sonraki
        </button>
      </div>

      <CompanyDetailModal
        isOpen={isDetailModalOpen}
        onClose={() => setIsDetailModalOpen(false)}
        companyId={selectedCompanyId}
      />
    </div>
  );
};

export default Companies;
