import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import AddSectorModal from './AddSectorModal';
import EditSectorModal from './EditSectorModal';
import Swal from 'sweetalert2';

const Sectors = () => {
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sectorsPerPage] = useState(10);
  const [totalSectors, setTotalSectors] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('Tümü');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSector, setSelectedSector] = useState(null);

  useEffect(() => {
    fetchSectors();
    fetchTotalSectors();
  }, [searchTerm, currentPage, selectedFilter]);

  const fetchTotalSectors = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'sectors'));
      setTotalSectors(querySnapshot.size);
    } catch (error) {
      console.error('Error fetching total sectors:', error);
    }
  };

  const fetchSectors = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'sectors'));
      const allSectors = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      let filteredSectors = allSectors;

      if (searchTerm) {
        filteredSectors = filteredSectors.filter((sector) =>
          sector.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (selectedFilter !== 'Tümü') {
        filteredSectors = filteredSectors.filter((sector) => sector.type === selectedFilter);
      }

      // Alt sektörler için ana sektör ismini bul
      for (let sector of filteredSectors) {
        if (sector.type === 'Alt Sektör') {
          try {
            const mainSectorDoc = await getDoc(doc(db, 'sectors', sector.mainSectorId));
            const mainSectorData = mainSectorDoc.data();
            sector.mainSectorName = mainSectorData ? mainSectorData.name : 'Ana Sektör Bilinmiyor';
          } catch (error) {
            console.error(`Error fetching main sector for ${sector.name}:`, error);
            sector.mainSectorName = 'Ana Sektör Bilinmiyor';
          }
        }
      }

      setSectors(filteredSectors.slice((currentPage - 1) * sectorsPerPage, currentPage * sectorsPerPage));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching sectors:', error);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDelete = async (sector) => {
    if (sector.type === 'Ana Sektör') {
      // Bu ana sektöre bağlı alt sektörleri bul
      const querySnapshot = await getDocs(collection(db, 'sectors'));
      const allSectors = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const linkedSubsectors = allSectors.filter((subsector) => subsector.mainSectorId === sector.id);

      if (linkedSubsectors.length > 0) {
        const subsectorList = linkedSubsectors.map((subsector) => `<li>${subsector.name}</li>`).join('');

        Swal.fire({
          title: 'Emin misiniz?',
          html: `<p>Silmeye çalıştığınız sektör bir ana sektördür ve silmeyi onaylarsanız aşağıda listelenen bu sektöre bağlı alt sektörler de silinecektir:</p><ul>${subsectorList}</ul>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet, sil!',
          cancelButtonText: 'Hayır, iptal et!',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Ana sektör ve alt sektörleri sil
              await deleteDoc(doc(db, 'sectors', sector.id));
              await Promise.all(linkedSubsectors.map((subsector) => deleteDoc(doc(db, 'sectors', subsector.id))));
              Swal.fire('Silindi!', 'Ana sektör ve bağlı alt sektörler başarıyla silindi.', 'success');
              fetchSectors();
            } catch (error) {
              console.error('Error deleting sector or subsectors:', error);
              Swal.fire('Hata!', 'Sektör silinirken bir hata oluştu.', 'error');
            }
          }
        });
      } else {
        // Ana sektör silme onayı
        Swal.fire({
          title: 'Emin misiniz?',
          text: 'Bu ana sektörü silmek istediğinizden emin misiniz?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet, sil!',
          cancelButtonText: 'Hayır, iptal et!',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await deleteDoc(doc(db, 'sectors', sector.id));
              Swal.fire('Silindi!', 'Ana sektör başarıyla silindi.', 'success');
              fetchSectors();
            } catch (error) {
              console.error('Error deleting sector:', error);
              Swal.fire('Hata!', 'Sektör silinirken bir hata oluştu.', 'error');
            }
          }
        });
      }
    } else {
      // Alt sektör silme onayı
      Swal.fire({
        title: 'Emin misiniz?',
        text: 'Bu alt sektörü silmek istediğinizden emin misiniz?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, sil!',
        cancelButtonText: 'Hayır, iptal et!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteDoc(doc(db, 'sectors', sector.id));
            Swal.fire('Silindi!', 'Alt sektör başarıyla silindi.', 'success');
            fetchSectors();
          } catch (error) {
            console.error('Error deleting sector:', error);
            Swal.fire('Hata!', 'Sektör silinirken bir hata oluştu.', 'error');
          }
        }
      });
    }
  };

  const handleEdit = (sector) => {
    setSelectedSector(sector);
    setIsEditModalOpen(true);
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4 space-y-2 md:space-y-0 md:space-x-4">
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => handleFilterClick('Tümü')}
            className={`py-2 px-4 rounded-lg ${selectedFilter === 'Tümü' ? 'bg-gray-200 text-gray-800' : 'text-gray-500'}`}
          >
            Tümü
          </button>
          <button
            onClick={() => handleFilterClick('Ana Sektör')}
            className={`py-2 px-4 rounded-lg ${selectedFilter === 'Ana Sektör' ? 'bg-gray-200 text-gray-800' : 'text-gray-500'}`}
          >
            Ana Sektör
          </button>
          <button
            onClick={() => handleFilterClick('Alt Sektör')}
            className={`py-2 px-4 rounded-lg ${selectedFilter === 'Alt Sektör' ? 'bg-gray-200 text-gray-800' : 'text-gray-500'}`}
          >
            Alt Sektör
          </button>
        </div>
        <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto">
          <input
            type="text"
            placeholder="Search"
            className="p-2 border border-gray-300 rounded-md w-full md:w-72"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="py-2 px-4 bg-blue-500 text-white rounded-lg"
          >
            Sektör Ekle
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Sektör Adı
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Tür
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Aksiyonlar</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              <tr>
                <td colSpan="3" className="py-4 text-center text-sm font-medium text-gray-500">
                  Loading...
                </td>
              </tr>
            ) : (
              sectors.map((sector) => (
                <tr key={sector.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {sector.type === 'Alt Sektör' ? (
                      <span>
                        <span className="text-gray-600">{sector.mainSectorName}</span> /{' '}
                        <span className="text-gray-900">{sector.name}</span>
                      </span>
                    ) : (
                      sector.name
                    )}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        sector.type === 'Ana Sektör' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                      }`}
                    >
                      {sector.type}
                    </span>
                  </td>
                  <td className="whitespace-nowrap py-4 text-right text-sm font-medium">
                    <button
                      onClick={() => handleEdit(sector)}
                      className="text-indigo-600 hover:text-indigo-900 mr-4"
                    >
                      Düzenle
                    </button>
                    <button
                      onClick={() => handleDelete(sector)}
                      className="text-red-600 hover:text-red-900"
                    >
                      Sil
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center mt-4 gap-2">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Önceki
        </button>
        <span>
          {totalSectors > 0 &&
            `${(currentPage - 1) * sectorsPerPage + 1} - ${Math.min(currentPage * sectorsPerPage, totalSectors)} / ${totalSectors}`}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage * sectorsPerPage >= totalSectors}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Sonraki
        </button>
      </div>

      <AddSectorModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          fetchSectors(); // Sayfayı yenile
        }}
      />
      <EditSectorModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          fetchSectors(); // Sayfayı yenile
        }}
        sector={selectedSector}
      />
    </div>
  );
};

export default Sectors;
