import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Swal from 'sweetalert2';

const SubscriptionPlans = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [planName, setPlanName] = useState('');
  const [planPrice, setPlanPrice] = useState('');
  const [planDuration, setPlanDuration] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'SubscriptionPlans'));
      const allPlans = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPlans(allPlans);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching plans:', error);
      setLoading(false);
    }
  };

  const handleUpdatePlan = async () => {
    try {
      await updateDoc(doc(db, 'SubscriptionPlans', selectedPlan.id), {
        name: planName,
        price: planPrice,
        duration: planDuration,
      });
      Swal.fire('Güncellendi!', 'Abonelik planı başarıyla güncellendi.', 'success');
      fetchPlans();
      resetForm();
    } catch (error) {
      console.error('Error updating plan:', error);
      Swal.fire('Hata!', 'Abonelik planı güncellenirken bir hata oluştu.', 'error');
    }
  };

  const handleDelete = async (plan) => {
    Swal.fire({
      title: 'Emin misiniz?',
      text: "Bu abonelik planını silmek istediğinizden emin misiniz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, sil!',
      cancelButtonText: 'Hayır, iptal et!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, 'SubscriptionPlans', plan.id));
          Swal.fire('Silindi!', 'Abonelik planı başarıyla silindi.', 'success');
          fetchPlans();
        } catch (error) {
          console.error('Error deleting plan:', error);
          Swal.fire('Hata!', 'Abonelik planı silinirken bir hata oluştu.', 'error');
        }
      }
    });
  };

  const handleEdit = (plan) => {
    setSelectedPlan(plan);
    setPlanName(plan.name);
    setPlanPrice(plan.price);
    setPlanDuration(plan.duration);
    setIsEditMode(true);
    setShowModal(true);
  };

  const resetForm = () => {
    setPlanName('');
    setPlanPrice('');
    setPlanDuration('');
    setIsEditMode(false);
    setSelectedPlan(null);
    setShowModal(false);
  };

  return (
    <div className="p-4">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Plan Adı
            </th>
            <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
              Fiyat
            </th>
            <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
              Süre (Gün)
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Aksiyonlar</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {loading ? (
            <tr>
              <td colSpan="4" className="py-4 text-center text-sm font-medium text-gray-500">
                Yükleniyor...
              </td>
            </tr>
          ) : (
            plans.map(plan => (
              <tr key={plan.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {plan.name}
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                  {plan.price}
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                  {plan.duration}
                </td>
                <td className="whitespace-nowrap py-4 text-right text-sm font-medium">
                  <button
                    onClick={() => handleEdit(plan)}
                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                  >
                    Düzenle
                  </button>
                  <button
                    onClick={() => handleDelete(plan)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Düzenleme Modalı */}
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Abonelik Planını Düzenle
                    </h3>
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder="Plan Adı"
                        className="p-2 border border-gray-300 rounded-md mb-4 w-full"
                        value={planName}
                        onChange={(e) => setPlanName(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Fiyat"
                        className="p-2 border border-gray-300 rounded-md mb-4 w-full"
                        value={planPrice}
                        onChange={(e) => setPlanPrice(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Süre (gün olarak)"
                        className="p-2 border border-gray-300 rounded-md mb-4 w-full"
                        value={planDuration}
                        onChange={(e) => setPlanDuration(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleUpdatePlan}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Güncelle
                </button>
                <button
                  onClick={resetForm}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  İptal
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlans;
