import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  HomeIcon,
  UsersIcon,
  BriefcaseIcon,
  FolderIcon,
  ShoppingCartIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  PhoneIcon,
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  UserGroupIcon,
  BanknotesIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

const Sidebar = ({ sidebarOpen, setSidebarOpen, role }) => {
  const [cmsOpen, setCmsOpen] = useState(false);

  return (
    <div
      className={`fixed top-0 left-0 h-full w-64 bg-gray-900 p-4 overflow-y-auto transition-transform transform ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } md:translate-x-0 md:w-64 z-40`}
    >
      <div className="flex items-center justify-between mb-8">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ihale-6cb24.appspot.com/o/Logo.png?alt=media&token=7d143957-6812-4174-bf78-723d103e75a4"
          alt="Logo"
          className="h-10"
        />
        {/* Close button for mobile sidebar */}
        <button
          className="md:hidden p-1 bg-gray-700 rounded-full"
          onClick={() => setSidebarOpen(false)}
        >
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
      </div>
      <ul className="space-y-4">
        <li>
          <Link
            to="/home"
            className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
            onClick={() => setSidebarOpen(false)}
          >
            <HomeIcon className="h-5 w-5 mr-3" />
            Anasayfa
          </Link>
        </li>

        {/* Role SuperAdmin, Admin veya Yönetici değilse Firmalar, İlanlar ve Siparişler menüsünü göstermeyelim */}
        {(role === 'SuperAdmin' || role === 'Admin' || role === 'Yönetici') && (
          <>
            <li>
              <Link
                to="/companies"
                className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
                onClick={() => setSidebarOpen(false)}
              >
                <UsersIcon className="h-5 w-5 mr-3" />
                Firmalar
              </Link>
            </li>
            <li>
              <Link
                to="/ads"
                className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
                onClick={() => setSidebarOpen(false)}
              >
                <FolderIcon className="h-5 w-5 mr-3" />
                İlanlar
              </Link>
            </li>
            <li>
              <Link
                to="/orders"
                className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
                onClick={() => setSidebarOpen(false)}
              >
                <ShoppingCartIcon className="h-5 w-5 mr-3" />
                Siparişler
              </Link>
            </li>
          </>
        )}

        {/* Role SuperAdmin veya Admin değilse Sektörler menüsünü göstermeyelim */}
        {(role === 'SuperAdmin' || role === 'Admin') && (
          <li>
            <Link
              to="/sectors"
              className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
              onClick={() => setSidebarOpen(false)}
            >
              <BriefcaseIcon className="h-5 w-5 mr-3" />
              Sektörler
            </Link>
          </li>
        )}

        {/* Role SuperAdmin veya Admin değilse CMS kısmını ve abonelik planlarını göstermeyelim */}
        {(role === 'SuperAdmin' || role === 'Admin') && (
          <>
            <li>
              <button
                className="flex items-center p-2 w-full text-gray-300 hover:bg-gray-800 rounded-md focus:outline-none"
                onClick={() => setCmsOpen(!cmsOpen)}
              >
                <DocumentTextIcon className="h-5 w-5 mr-3" />
                CMS
                <ChevronDownIcon
                  className={`h-5 w-5 ml-auto transition-transform ${
                    cmsOpen ? 'rotate-180' : ''
                  }`}
                />
              </button>
              {cmsOpen && (
                <ul className="pl-6 mt-2 space-y-2">
                  <li>
                    <Link
                      to="/cms/seo"
                      className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
                      onClick={() => setSidebarOpen(false)}
                    >
                      SEO
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cms/blogs"
                      className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
                      onClick={() => setSidebarOpen(false)}
                    >
                      Bloglar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cms/contactinfos"
                      className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
                      onClick={() => setSidebarOpen(false)}
                    >
                      İletişim Bilgileri
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cms/api"
                      className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
                      onClick={() => setSidebarOpen(false)}
                    >
                      Api Bilgileri
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link
                to="/subscription"
                className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
                onClick={() => setSidebarOpen(false)}
              >
                <BanknotesIcon className="h-5 w-5 mr-3" />
                Abonelik Planları
              </Link>
            </li>
          </>
        )}

        <li>
          <Link
            to="/support"
            className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
            onClick={() => setSidebarOpen(false)}
          >
            <QuestionMarkCircleIcon className="h-5 w-5 mr-3" />
            Destek Talepleri
          </Link>
        </li>
        <li>
          <Link
            to="/contactforms"
            className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
            onClick={() => setSidebarOpen(false)}
          >
            <PhoneIcon className="h-5 w-5 mr-3" />
            İletişim Formları
          </Link>
        </li>

        {/* Role SuperAdmin ise Admin Users menüsünü göster */}
        {role === 'SuperAdmin' && (
          <li>
            <Link
              to="/users"
              className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
              onClick={() => setSidebarOpen(false)}
            >
              <UserGroupIcon className="h-5 w-5 mr-3" />
              Admin Users
            </Link>
          </li>
        )}
        <li>
          <button
            className="flex items-center p-2 text-gray-300 hover:bg-gray-800 rounded-md"
            onClick={() => {
              /* logout function */
            }}
          >
            <ArrowLeftOnRectangleIcon className="h-5 w-5 mr-3" />
            Çıkış Yap
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
