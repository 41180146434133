import React from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';

const Header = ({ setSidebarOpen }) => {
  return (
    <div className="w-full bg-white shadow p-4 flex justify-between items-center">
      <button
        className="md:hidden p-2 bg-gray-800 rounded-full"
        onClick={() => setSidebarOpen(true)}
      >
        <Bars3Icon className="h-6 w-6 text-white" />
      </button>
      <div className="text-2xl font-semibold">Dashboard</div>
    </div>
  );
};

export default Header;
