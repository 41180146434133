import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const ApiDetails = () => {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchApiData();
  }, []);

  const fetchApiData = async () => {
    setLoading(true);
    try {
      const docRef = doc(db, 'Apis', 'lb9ZrGlIGxwEG2XsBkpZ');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setApiData(docSnap.data());
      } else {
        console.error('No such document!');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching API data:', error);
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      {loading ? (
        <div className="text-center text-gray-500">Yükleniyor...</div>
      ) : apiData ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Key
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Provider
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Secret Key
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Type
                </th>
                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                  Durum
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Aksiyonlar</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                  {apiData.key}
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                  {apiData.provider}
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                  {apiData.secretkey}
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                  {apiData.type}
                </td>
                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                  {/* Durum kısmı eklenecek */}
                  Durum bilgisi buraya gelecek
                </td>
                <td className="whitespace-nowrap py-4 text-right text-sm font-medium">
                  {apiData.type === 'mail' && (
                    <button
                      onClick={() => {
                        /* Boş onclick, daha sonra doldurulacak */
                      }}
                      className="py-2 px-4 bg-blue-500 text-white rounded-lg"
                    >
                      Test Emaili Gönder
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center text-gray-500">Veri bulunamadı.</div>
      )}
    </div>
  );
};

export default ApiDetails;
