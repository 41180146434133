import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { PaperClipIcon } from '@heroicons/react/20/solid';

const CompanyDetailModal = ({ isOpen, onClose, companyId }) => {
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (companyId) {
        try {
          const companyRef = doc(db, 'companies', companyId);
          const companyDoc = await getDoc(companyRef);
          if (companyDoc.exists()) {
            setCompanyData(companyDoc.data());
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching company data:', error);
        }
      }
    };

    fetchCompanyData();
  }, [companyId]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center border-b pb-4">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-semibold leading-6 text-gray-900"
                  >
                    Firma Detayı
                  </Dialog.Title>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-600"
                    onClick={onClose}
                  >
                    <span className="sr-only">Kapat</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="mt-4 space-y-4">
                  {companyData ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
                      <div>
                        <p className="text-sm font-medium text-gray-500">Firma Adı</p>
                        <p className="text-base font-semibold text-gray-900">{companyData.companyName}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">Firma Ünvanı</p>
                        <p className="text-base font-semibold text-gray-900">{companyData.companyTitle}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">Telefon Numarası</p>
                        <p className="text-base font-semibold text-gray-900">{companyData.phoneNumber}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">E-Posta</p>
                        <p className="text-base font-semibold text-gray-900">{companyData.email}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">Sektörler</p>
                        <p className="text-base font-semibold text-gray-900">{companyData.sectors?.join(', ')}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">Adres</p>
                        <p className="text-base font-semibold text-gray-900">{companyData.address}</p>
                      </div>
                      <div className="md:col-span-2">
                        <p className="text-sm font-medium text-gray-500">İl / İlçe / Posta Kodu</p>
                        <p className="text-base font-semibold text-gray-900">{`${companyData.city} / ${companyData.district} / ${companyData.postalCode}`}</p>
                      </div>
                      <div className="md:col-span-2">
                        <p className="text-sm font-medium text-gray-500">Vergi Levhası</p>
                        <div className="flex items-center space-x-2">
                          <PaperClipIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          {companyData.taxDocumentURL ? (
                            <a
                              href={companyData.taxDocumentURL}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800"
                            >
                              vergi_levhasi_2024.pdf
                            </a>
                          ) : (
                            <span className="text-gray-500">Yüklenmemiş</span>
                          )}
                        </div>
                      </div>
                      <div className="md:col-span-2">
                        <p className="text-sm font-medium text-gray-500">Profil Türü</p>
                        <div className="flex items-center space-x-4">
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={companyData.isBuyer}
                              readOnly
                              className="mr-2 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <span className="text-gray-700">Alıcı Profili Açmak İstiyorum</span>
                          </label>
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={companyData.isSeller}
                              readOnly
                              className="mr-2 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <span className="text-gray-700">Satıcı Profili Açmak İstiyorum</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500">Yükleniyor...</p>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CompanyDetailModal;
