import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import Modal from 'react-modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';

export default function Support() {
  const [supportRequests, setSupportRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
  const [replySubject, setReplySubject] = useState('');
  const [replyMessage, setReplyMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage] = useState(10);
  const [totalRequests, setTotalRequests] = useState(0);

  useEffect(() => {
    fetchSupportRequests();
  }, [currentPage]);

  const fetchSupportRequests = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'supports'));
      const requests = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        no: `#${String(index + 1).padStart(3, '0')}`,
        ...doc.data(),
      }));

      setTotalRequests(requests.length);
      const offset = (currentPage - 1) * requestsPerPage;
      const paginatedRequests = requests.slice(offset, offset + requestsPerPage);

      const requestsWithEmails = await Promise.all(
        paginatedRequests.map(async (request) => {
          const userDoc = await getDoc(doc(db, 'users', request.userId));
          const userData = userDoc.exists() ? userDoc.data() : {};

          return {
            ...request,
            email: userData.email || 'E-posta bulunamadı',
            fullName: userData.fullName || 'İsim bulunamadı',
            isReplySent: request.isReplySent || false,
          };
        })
      );

      setSupportRequests(requestsWithEmails);
    } catch (error) {
      console.error('Error fetching support requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDetailClick = (request) => {
    setSelectedRequest(request);
  };

  const closeModal = () => {
    setSelectedRequest(null);
  };

  const openReplyModal = () => {
    setReplySubject(`Re: ${selectedRequest?.subject || ''}`);
    setReplyMessage('');
    setIsReplyModalOpen(true);
  };

  const closeReplyModal = () => {
    setIsReplyModalOpen(false);
  };

  const handleSendReply = async () => {
    try {
      const response = await fetch(
        'https://us-central1-functions-test-87bf0.cloudfunctions.net/app/sendEmail',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            senderEmail: 'destek@e-tedarikci.com',
            recipientEmail: selectedRequest?.email,
            subject: replySubject,
            message: replyMessage,
          }),
        }
      );

      const result = await response.json();
      if (result.success) {
        await updateDoc(doc(db, 'supports', selectedRequest.id), {
          isReplySent: true,
        });

        Swal.fire({
          icon: 'success',
          title: 'Yanıt başarıyla gönderildi!',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          closeReplyModal();
          closeModal();
          fetchSupportRequests();
        });
      } else {
        console.error('Error sending email:', result.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const formatDate = (timestamp) => {
    try {
      if (timestamp && timestamp.toDate) {
        return format(timestamp.toDate(), 'dd.MM.yyyy HH:mm', { locale: tr });
      }
      return format(new Date(timestamp), 'dd.MM.yyyy HH:mm', { locale: tr });
    } catch (error) {
      console.error('Invalid date:', error);
      return 'Geçersiz Tarih';
    }
  };

  const handleDownloadAttachment = (attachmentURL) => {
    if (attachmentURL) {
      const link = document.createElement('a');
      link.href = attachmentURL;
      link.download = attachmentURL.split('/').pop(); // Dosya adını almak için
      link.click();
    }
  };

  const handleNextPage = () => {
    if (currentPage * requestsPerPage < totalRequests) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-semibold mb-4">Destek Talepleri</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                ID
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Şirket
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Kullanıcı
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                E-Posta
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Tarih
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Durum
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Detay</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              <tr>
                <td colSpan="7" className="py-4 text-center text-sm font-medium text-gray-500">
                  Yükleniyor...
                </td>
              </tr>
            ) : (
              supportRequests.map((request) => (
                <tr key={request.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {request.no}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                    {request.companyId}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{request.fullName}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{request.email}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                    {formatDate(request.createdAt)}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                    {request.isReplySent ? 'Yanıt Verildi' : 'Yanıt Verilmedi'}
                  </td>
                  <td className="whitespace-nowrap py-4 text-right text-sm font-medium">
                    <button
                      onClick={() => handleDetailClick(request)}
                      className="text-indigo-600 hover:text-indigo-900 mr-4"
                    >
                      Detay
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center mt-4 gap-2">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Önceki
        </button>
        <span>
          {totalRequests > 0 &&
            `${(currentPage - 1) * requestsPerPage + 1} - ${Math.min(
              currentPage * requestsPerPage,
              totalRequests
            )} / ${totalRequests}`}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage * requestsPerPage >= totalRequests}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Sonraki
        </button>
      </div>

      {selectedRequest && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-7/12 p-6 relative z-50">
            <div className="flex justify-between items-center border-b pb-4 mb-4">
              <h2 className="text-xl font-semibold">Destek Detayı</h2>
              <button onClick={closeModal} className="text-gray-600 hover:text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex justify-between border-b pb-2">
                <span className="text-gray-500 w-1/3">Kullanıcı</span>
                <span className="text-gray-900 w-2/3">{selectedRequest.fullName}</span>
              </div>
              <div className="flex justify-between border-b pb-2">
                <span className="text-gray-500 w-1/3">Şirket</span>
                <span className="text-gray-900 w-2/3">{selectedRequest.companyId}</span>
              </div>
              <div className="flex justify-between border-b pb-2">
                <span className="text-gray-500 w-1/3">E-Posta</span>
                <span className="text-gray-900 w-2/3">{selectedRequest.email}</span>
              </div>
              <div className="flex justify-between border-b pb-2">
                <span className="text-gray-500 w-1/3">Tarih</span>
                <span className="text-gray-900 w-2/3">{formatDate(selectedRequest.createdAt)}</span>
              </div>

              <div className="flex justify-between border-b pb-2">
                <span className="text-gray-500 w-1/3">Ekler</span>
                <div className="text-gray-900 w-2/3">
                  {selectedRequest.attachmentURLs && selectedRequest.attachmentURLs.length > 0 ? (
                    selectedRequest.attachmentURLs.map((attachmentURL, index) => (
                      <div key={index} className="mb-2">
                        <button
                          onClick={() => handleDownloadAttachment(attachmentURL)}
                          className="text-blue-500 hover:underline"
                        >
                          Dosyayı İndir {index + 1}
                        </button>
                      </div>
                    ))
                  ) : (
                    <span>Yüklenen dosya bulunamadı</span>
                  )}
                </div>
              </div>

              <div className="flex border-b pb-2">
                <span className="text-gray-500 w-1/3">Mesaj</span>
                <div className="text-gray-900 w-2/3 whitespace-pre-line">
                  {selectedRequest.message}
                </div>
              </div>
              <div className="text-right">
                <button
                  onClick={openReplyModal}
                  className="mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg"
                >
                  Yanıtla
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={isReplyModalOpen}
        onRequestClose={closeReplyModal}
        contentLabel="Yanıtla"
        ariaHideApp={false}
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 z-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-4xl relative">
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            onClick={closeReplyModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="text-2xl font-bold mb-4">Yanıtla</h2>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Gönderen</label>
            <input
              type="text"
              value="destek@e-tedarikci.com"
              disabled
              className="mt-1 p-2 w-full border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Alıcı</label>
            <input
              type="text"
              value={selectedRequest?.email || ''}
              disabled
              className="mt-1 p-2 w-full border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Konu</label>
            <input
              type="text"
              value={replySubject}
              onChange={(e) => setReplySubject(e.target.value)}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">İleti</label>
            <div className="bg-white h-36">
              <ReactQuill
                theme="snow"
                value={replyMessage}
                onChange={setReplyMessage}
                className="bg-white h-full"
              />
            </div>
          </div>
          <div className="flex justify-end items-center mt-4">
            <button
              onClick={handleSendReply}
              className="py-2 mt-10 px-4 bg-blue-500 text-white rounded-lg"
            >
              Gönder
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
