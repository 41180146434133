import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Companies from './components/Companies';
import Sectors from './components/Sectors';
import Ads from './components/Ads';
import Orders from './components/Orders';
import Seo from './components/Cms/Seo';
import Blogs from './components/Cms/Blogs';
import ContactInfos from './components/Cms/ContactInfos';
import ApiDetails from './components/ApiDetails';
import Support from './components/Support';
import SubscriptionPlans from './components/SubscriptionPlans';
import ContactForms from './components/ContactForms';
import AdminUsers from './components/AdminUsers';
import Layout from './components/Layout';
import { AuthProvider, useAuth } from './context/AuthContext';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin', 'Yönetici', 'User']}>
                <Layout>
                  <Home />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/companies"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin', 'Yönetici']}>
                <Layout>
                  <Companies />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/sectors"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin']}>
                <Layout>
                  <Sectors />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/ads"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin', 'Yönetici']}>
                <Layout>
                  <Ads />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin', 'Yönetici']}>
                <Layout>
                  <Orders />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/cms/seo"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin']}>
                <Layout>
                  <Seo />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/cms/blogs"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin']}>
                <Layout>
                  <Blogs />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/cms/contactinfos"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin']}>
                <Layout>
                  <ContactInfos />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/cms/api"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin']}>
                <Layout>
                  <ApiDetails />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/support"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin', 'Yönetici', 'User']}>
                <Layout>
                  <Support />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin']}>
                <Layout>
                  <SubscriptionPlans />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/contactforms"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin', 'Admin', 'Yönetici', 'User']}>
                <Layout>
                  <ContactForms />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute allowedRoles={['SuperAdmin']}>
                <Layout>
                  <AdminUsers />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

// ProtectedRoute component to ensure only authenticated users with appropriate roles can access routes
const ProtectedRoute = ({ children, allowedRoles }) => {
  const { currentUser, role } = useAuth(); // currentUser ve role bilgilerini alıyoruz

  // Kullanıcı yoksa login sayfasına yönlendir
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Eğer role, allowedRoles içinde değilse anasayfaya yönlendir ve hata mesajı göster
  if (!allowedRoles.includes(role)) {
    alert('Yetkisiz erişim. Bu sayfaya erişim izniniz yok.');
    return <Navigate to="/home" />;
  }

  // Eğer yetki varsa çocuk bileşenleri render et
  return children;
};

export default App;
