import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Swal from 'sweetalert2';

const EditSectorModal = ({ isOpen, onClose, sector }) => {
  const [sectorName, setSectorName] = useState('');
  const [isMainSector, setIsMainSector] = useState(true);
  const [mainSectors, setMainSectors] = useState([]);
  const [selectedMainSector, setSelectedMainSector] = useState('');

  useEffect(() => {
    if (sector) {
      setSectorName(sector.name);
      setIsMainSector(sector.type === 'Ana Sektör');
      if (sector.mainSectorId) {
        setSelectedMainSector(sector.mainSectorId);
      }
    }
    if (!isMainSector) {
      fetchMainSectors();
    }
  }, [sector, isMainSector]);

  const fetchMainSectors = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'sectors'));
      const sectors = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMainSectors(sectors.filter(sector => sector.type === 'Ana Sektör'));
    } catch (error) {
      console.error('Error fetching main sectors:', error);
    }
  };

  const handleUpdateSector = async () => {
    if (!sectorName.trim()) {
      Swal.fire('Uyarı!', 'Lütfen sektör adı giriniz.', 'warning');
      return;
    }

    try {
      await updateDoc(doc(db, 'sectors', sector.id), {
        name: sectorName,
        type: isMainSector ? 'Ana Sektör' : 'Alt Sektör',
        mainSectorId: isMainSector ? null : selectedMainSector,
        mainSectorName: isMainSector ? null : mainSectors.find(ms => ms.id === selectedMainSector)?.name,
      });
      Swal.fire('Başarılı!', 'Sektör başarıyla güncellendi.', 'success');
      onClose();
    } catch (error) {
      console.error('Error updating sector:', error);
      Swal.fire('Hata!', 'Sektör güncellenirken bir hata oluştu.', 'error');
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg p-4 w-96">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-bold">Sektör Düzenle</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
              &times;
            </button>
          </div>
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="mainSector"
                name="sectorType"
                checked={isMainSector}
                onChange={() => setIsMainSector(true)}
                className="mr-2"
              />
              <label htmlFor="mainSector" className="mr-4">Ana Sektör</label>
              <input
                type="radio"
                id="subSector"
                name="sectorType"
                checked={!isMainSector}
                onChange={() => setIsMainSector(false)}
                className="mr-2"
              />
              <label htmlFor="subSector">Alt Sektör</label>
            </div>
            {!isMainSector && (
              <div className="mb-2">
                <label htmlFor="mainSectorSelect" className="block text-sm font-medium text-gray-700 mb-1">Ana Sektör</label>
                <select
                  id="mainSectorSelect"
                  value={selectedMainSector}
                  onChange={(e) => setSelectedMainSector(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="" disabled>Sektör Seç</option>
                  {mainSectors.map(sector => (
                    <option key={sector.id} value={sector.id}>{sector.name}</option>
                  ))}
                </select>
              </div>
            )}
            <div>
              <label htmlFor="sectorName" className="block text-sm font-medium text-gray-700 mb-1">
                {isMainSector ? 'Sektör Adı' : 'Alt Sektör Adı'}
              </label>
              <input
                id="sectorName"
                type="text"
                placeholder="örn. Sağlık"
                value={sectorName}
                onChange={(e) => setSectorName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleUpdateSector}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              Güncelle
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default EditSectorModal;
