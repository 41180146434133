import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Swal from 'sweetalert2';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';

const roles = ['All', 'SuperAdmin', 'Admin', 'Yönetici', 'Müşteri Hizmetleri', 'Reklam Bölümü', 'Halkla İlişkiler'];

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchTotalUsers();
  }, [searchTerm, currentPage, selectedFilter]);

  const fetchTotalUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'adminusers'));
      setTotalUsers(querySnapshot.size);
    } catch (error) {
      console.error('Error fetching total users:', error);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'adminusers'));
      let allUsers = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      if (searchTerm) {
        allUsers = allUsers.filter((user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (selectedFilter !== 'All') {
        allUsers = allUsers.filter((user) => user.role === selectedFilter);
      }

      setUsers(allUsers.slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDelete = (user) => {
    Swal.fire('Silindi!', 'Kullanıcı başarıyla silindi.', 'success');
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row items-center justify-between mb-4 gap-2">
        <div className="flex flex-wrap space-x-2">
          {roles.map((role) => (
            <button
              key={role}
              onClick={() => handleFilterClick(role)}
              className={`py-2 px-4 rounded-lg ${
                selectedFilter === role ? 'bg-gray-200 text-gray-800' : 'text-gray-500'
              }`}
            >
              {role}
            </button>
          ))}
        </div>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search"
            className="p-2 border border-gray-300 rounded-md w-full md:w-72"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="py-2 px-4 bg-blue-500 text-white rounded-lg"
          >
            Add User
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Email
              </th>
              <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                Role
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              <tr>
                <td colSpan="4" className="py-4 text-center text-sm font-medium text-gray-500">
                  Loading...
                </td>
              </tr>
            ) : (
              users.map((user) => (
                <tr key={user.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {user.fullName}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">{user.email}</td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.role === 'Admin'
                          ? 'bg-blue-100 text-blue-800'
                          : 'bg-green-100 text-green-800'
                      }`}
                    >
                      {user.role}
                    </span>
                  </td>
                  <td className="whitespace-nowrap py-4 text-right text-sm font-medium">
                    <button
                      onClick={() => handleEdit(user)}
                      className="text-indigo-600 hover:text-indigo-900 mr-4"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(user)}
                      className="text-red-600 hover:text-red-900"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center mt-4 gap-2">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          {totalUsers > 0 &&
            `${(currentPage - 1) * usersPerPage + 1} - ${Math.min(
              currentPage * usersPerPage,
              totalUsers
            )} / ${totalUsers}`}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage * usersPerPage >= totalUsers}
          className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      <AddUserModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          fetchUsers();
        }}
      />
      <EditUserModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          fetchUsers();
        }}
        user={selectedUser}
      />
    </div>
  );
};

export default AdminUsers;
